import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { Col, Container, Row } from 'react-bootstrap';

import { Spinner, SSOContainer } from './sso.styles';
import { Button, H2, Subheader } from '../../components/index';
import { useAuth } from '../../features/auth/auth.hook';

const CloseWindowButton = (
  <Button
    id="SSOCloseWindow"
    variant="primary"
    style={{ width: 'auto', marginTop: '2rem' }}
    onClick={() => { window.close(); }}
  >
    Close this Window
  </Button>
);

const SSO = (): any => {
  const search = useLocation().search;
  const jwt = new URLSearchParams(search).get('jwt');
  const subscribercode =  new URLSearchParams(search).get('subscribercode');
  const { _loginSSO, _logout, authError } = useAuth();
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (jwt && subscribercode && authError !== 407) {
      _loginSSO(jwt, subscribercode);
    } else {
      setDisplayError(true);
      if (authError === 407) {
        _logout();
      }
    }
  }, []);

  useEffect(() => {
    if (authError !== null) {
      setDisplayError(true);
    }
  }, [authError]);

  return (
    <Flexbox flexDirection={'column'}>
      {displayError ? (
        <>
          <Subheader title='System Unavailable' centered />
          <Container role='main' id="main-content">
            <Row>
              <Col md={{span: 8, offset: 2}} style={{ paddingTop: 40, paddingBottom: 40, textAlign: 'center' }}>
                <H2>BECU Financial Health Pilot encountered a problem.</H2>
                <p>Please try again.</p>
                {CloseWindowButton}
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <SSOContainer role={'main'} id="main-content">
          <Row>
            <Col style={{ paddingTop: 40, paddingBottom: 40, textAlign: 'center' }}>
              <Spinner className='spinner' />
              <H2>Opening BECU Financial Health Pilot</H2>
            </Col>
          </Row>
        </SSOContainer>
      )}
    </Flexbox>
  );
};

export default SSO;
