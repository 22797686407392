import {
  SESSION_STATE_KEY,
  SSO_AUTH_KEY,
} from '../config/constants.config';

export const loadState = (): any | undefined => {
  try {
    const lastState = sessionStorage.getItem(SESSION_STATE_KEY);
    if (lastState === null) {
      return undefined;
    }
    const state = { auth: JSON.parse(lastState) };
    return state;
  } catch (err) {
    return undefined;
    // if session storage is off, return undefined
  }
};

export const saveState = (state: any): void => {
  try {
    sessionStorage.setItem(SESSION_STATE_KEY, JSON.stringify(state));
  } catch (err) {
    // ignore errors, silently fail if session storage is off
  }
};

export const getSsoAuthKey = (): string | undefined => {
  try {
    const ssoAuthKey = sessionStorage.getItem(SSO_AUTH_KEY);
    if (ssoAuthKey === null) {
      return undefined;
    }
    return ssoAuthKey;
  } catch (err) {
    return undefined;
  }
};

export const setSsoAuthKey = (key: any): void => {
  try {
    sessionStorage.setItem(SSO_AUTH_KEY, key);
  } catch (err) {
    // silent fail
  }
};
