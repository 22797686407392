import axios from 'axios';
import {
  HUB_SERVICE_URL,
  LOGIN_SERVICE_URL,
  TOKEN_TIME,
} from '../config/constants.config';

export const HUB_API = axios.create({
  baseURL: HUB_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'ocp-apim-subscription-key': 'd7adb62db6cf48ea8674c951152f32e0',
    // 'Origin': 'https://financialhealthhub.dev.becu.org/',
    Pragma: 'no-cache',
  },
});

export const AUTH_API = axios.create({
  baseURL: LOGIN_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'ocp-apim-subscription-key': 'd7adb62db6cf48ea8674c951152f32e0',
    // 'Origin': 'https://financialhealthhub.dev.becu.org/',
    'Token-Elapse-Setting': TOKEN_TIME,
    Pragma: 'no-cache',
  },
});
