import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

export const BaseButton = styled(Button)`
  width: ${({ autoWidth }) => (autoWidth) ? 'auto' : '100%'};
  min-height: 50px;
  font-size: 16px;
  font-weight: 600;
  border-width: 2px;
  border-radius: 10px;
  margin-top: ${({ marginTop }) => marginTop};
  &:disabled {
    cursor: not-allowed;
    opacity: 1;
  }
  &:focus-visible, &:active {
    box-shadow: none !important;
    outline: 2px solid ${({ theme }) => theme.color.actionBlue} !important;
    outline-offset: 2px;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  color: ${({ theme }) => theme.color.snow};
  background-color: ${({ theme }) => theme.color.harbor};
  border-color: ${({ theme }) => theme.color.harbor};
  &:disabled {
    color: ${({ theme }) => theme.color.stone};
    background-color: #CCCCCC;
    border-color: #CCCCCC;
  }
  &:focus-visible {
    background-color: ${({ theme }) => theme.color.harbor};
    border-color: ${({ theme }) => theme.color.harbor};
  }
  @media(hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.darkTeal};
      border-color: ${({ theme }) => theme.color.darkTeal};
    }
  }
  &:active {
    background-color: #000000 !important;
    border-color: #000000 !important;
  }
`;

export const SecondaryButton = styled(BaseButton)`
  color: ${({ theme }) => theme.color.obsidian} !important;
  background-color: ${({ theme }) => theme.color.snow};
  border-color: ${({ theme }) => theme.color.stone};
  &:disabled {
    color: #CCCCCC !important;
    background-color: ${({ theme }) => theme.color.snow};
    border-color: #CCCCCC;
  }
  &:focus {
    background-color: ${({ theme }) => theme.color.snow};
  }
  @media(hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.cloud};
    }
  }
  &:active {
    background-color: #E3E1DB !important;
  }
`;

export const LinkButton = styled(BaseButton)`
  color: ${({ theme }) => theme.color.harbor} !important;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  text-decoration-line: none;
  width: fit-content;
  min-height: 0;
  text-align: left;
  &:disabled {
    color: #CCCCCC !important;
  }
  &:focus {
    color: ${({ theme }) => theme.color.darkTeal};
  }
  @media(hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.cloud};
    }
  }
  &:active {
    background-color: #E3E1DB !important;
  }
`;