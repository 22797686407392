import { HUB_API } from '../../config/api.config';
import { USER_ROUTE } from '../../config/constants.config';

class UserService {
  getUser(token: string): any {
    return HUB_API.get(USER_ROUTE, { headers: { Authorization: `Bearer ${token}` } });
  }
}

export default new UserService();
