import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from './user.service';
import type User from '../../models/user.model';
import { type RootState } from '../../app/store';

interface UserState {
  currentUser: User | null;
}

const initialState: UserState = {
  currentUser: null,
};

export const getUser = createAsyncThunk('user/getUser', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const token = state.auth.session;
  const res = await UserService.getUser(token);
  return res.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.currentUser = action.payload.user;
    })
    builder.addCase(getUser.rejected, (state, action) => {
      state.currentUser = null;
    })
  },
});

export const selectCurrentUser = (state: RootState): User => state.user.currentUser;

export default userSlice.reducer;
