import styled from '@emotion/styled';

export const CardContainerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.color.obsidian}`};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.color.snow};
  padding-bottom: 6px;
`;

export const CardTitleContainer = styled('div')`
  padding: 10px;
  background-color: ${({ theme }) => theme.color.stone};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.small}px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.small}px;
`;
