import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { type RootState } from '@app/store';
import AuthService from './auth.service';
import { type API_CONSTANTS } from '../../config/constants.config';

interface AuthState {
  authError: any;
  loggedIn: boolean;
  session: string | null;
  status: API_CONSTANTS['STATUS'];
}

const initialState: AuthState = {
  authError: null,
  loggedIn: false,
  session: null,
  status: 'idle',
};

export const AUTH_EVENTS = {
  LOGIN: 'auth/login',
  SSO: 'auth/sso-login',
  LOGOUT: 'auth/logout',
};

export const login = createAsyncThunk(AUTH_EVENTS.LOGIN, async ({ userId, password }: any, thunkAPI) => {
  try {
    const res = await AuthService.login(userId, password);
    return res.data.token;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response ? error.response.status : 0);
  }
});

export const loginSSO = createAsyncThunk(AUTH_EVENTS.SSO, async ({ token, subscriberCode }: any, thunkAPI) => {
  try {
    const res = await AuthService.loginSSO(token, subscriberCode);
    return res.data.token;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response ? error.response.status : 0);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    resetLoginStatus(state) {
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.authError = null;
        state.loggedIn = true;
        state.status = 'fulfilled';
        state.session = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'rejected';
        state.authError = action.payload;
        state.session = null;
        state.loggedIn = false;
      })
      .addCase(loginSSO.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(loginSSO.fulfilled, (state, action) => {
        state.authError = null;
        state.loggedIn = true;
        state.status = 'fulfilled';
        state.session = action.payload;
      })
      .addCase(loginSSO.rejected, (state, action) => {
        state.status = 'rejected';
        state.authError = action.payload;
        state.session = null;
        state.loggedIn = false;
      })
  },
});

export const selectAuthError = (state: RootState): any => state.auth.authError;
export const selectLoggedIn = (state: RootState): boolean => state.auth.loggedIn;
export const selectSession = (state: RootState): string | null => state.auth.session;
export const selectStatus = (state: RootState): API_CONSTANTS['STATUS'] => state.auth.status;

export const { setLoggedIn, resetLoginStatus } = authSlice.actions;

export default authSlice.reducer;
