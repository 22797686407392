import styled from '@emotion/styled';
import Flexbox from 'flexbox-react';
import { Col } from 'react-bootstrap';

interface FooterLegalProps {
  big: boolean;
}

export const HeaderContainer = styled('div')`
  background-color: ${({ theme }) => theme.color.snow};
  padding: 0 24px;
`;

export const SubheaderContainer = styled('div')`
  background-color: ${({ theme }) => theme.color.obsidian};
  padding: 32px 0;
`;

export const SkipLink = styled('a')`
  position: absolute;
  transform: translateY(-200%);
  transition: transform 0.3s ease-out;
  color: #FFFFFF !important;
  background-color: ${({ theme }) => theme.color.obsidian};
  padding: 10px 15px;
  margin: 15px 10px;
  z-index: 2;
  &:focus {
    transform: translateY(0);
  }
`;

export const FooterSpacer = styled('div')`
  flex: 1;
`;

export const FooterContainer = styled(Flexbox)`
  width: 100%;
  background-color: ${({ theme }) => theme.color.cloud};
  padding: 16px 0;
`;

export const FooterLegal = styled(Col)<FooterLegalProps>`
  text-align: ${({ big }) => big ? 'inherit' : 'center'};
`;

export const FooterLinks = styled(Col)`

`;