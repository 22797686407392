import styled from '@emotion/styled';

export const LoginHeader = styled('div')`
  width: 100%;
  background-color: ${({ theme }) => theme.color.obsidian};
  position: relative;
  z-index: 100;
  box-shadow: 0 3px 3px #BCBFC2;
`;

export const ViewPassword = styled('button')`
  height: 44px;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border: ${({ theme }) => `1px solid ${theme.color.stone}`};
  border-radius: 2px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  &:focus-visible {
    z-index: 1;
  }
`;
