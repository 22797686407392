import { useMediaQuery } from 'react-responsive';

interface UseResponsiveReturnType {
  isXSScreen: boolean;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  isLargeScreen: boolean;
  isXLScreen: boolean;
  is2XLScreen: boolean;
}

const useResponsive = (): UseResponsiveReturnType => {
  const isXSScreen = useMediaQuery({ maxWidth: 575 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const isMediumScreen = useMediaQuery({ maxWidth: 991 });
  const isLargeScreen = useMediaQuery({ maxWidth: 1199 });
  const isXLScreen = useMediaQuery({ maxWidth: 1399 });
  const is2XLScreen = useMediaQuery({ minWidth: 1400 });

  return {
    isXSScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLScreen,
    is2XLScreen,
  };
};

export default useResponsive;