import styled from '@emotion/styled';
import Flexbox from 'flexbox-react';

export const ComponentContainer = styled(Flexbox)`
  flex-direction: column;
  gap: 24px;
  background-color: ${({ theme }) => theme.color.snow};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.storm};
  padding: 24px;
  max-width: 434px;
`;

export const GraphTotalText = styled('p')`
  font-family: Public Sans;
  font-weight: 700;
  color: ${({ theme }) => theme.color.obsidian};
  font-size: 45px;
  line-height: 45px;
  margin-bottom: 0;
`;
