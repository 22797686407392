import styled from '@emotion/styled';

export const Hero = styled('h1')`
  font-family: Public Sans;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
  font-size: 2.5rem;
  font-weight: 600px;
  @media screen and (max-width: 767px) {
    font-size: 36px;
  }
`

export const H1 = styled('h1')`
  font-family: Public Sans;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
  font-size: 2.25rem;
  font-weight: 600;
  @media screen and (max-width: 767px) {
    font-size: 32px;
  }
`;

export const H2 = styled('h2')`
  font-family: Public Sans;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
  font-size: 28px;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 28px;
  }
`;

export const H3 = styled('h3')`
  font-family: Public Sans;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
  font-size: 24px;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
`;

export const H4 = styled('h4')`
  font-family: Public Sans;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
  font-size: 1.5rem;
  font-weight: 600;
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
`;

export const Body = styled('p')`
  font-family: Public Sans;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
  font-size: 16px;
  margin-bottom: 0;
`;

export const BodyBold = styled('span')`
  font-weight: 700;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
`;

export const BodySmall = styled(Body)`
  font-size: 14px;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
`;

export const BodySmallBold = styled(BodySmall)`
  font-weight: 700;
  color: ${({ theme, color }) => color ?? theme.color.obsidian};
`

export const Label = styled('p')`
  font-family: Public Sans;
  color: #000000;
  font-size: 12px;
  margin-bottom: 0;
`;
