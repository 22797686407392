import styled from '@emotion/styled';

export const ContentContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled('div')`
  color: ${({ theme }) => theme.color.obsidian};
  width: 3rem;
  height: 3rem;
`;