import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';

export const NotFoundContainer = styled(Container)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotFoundImage = styled('img')<{ largeImage: boolean }>`
  min-width: 40px;
  max-width: ${({ largeImage }) => largeImage ? '250' : '200'}px;
  vertical-align: middle;
  border-style: none;
`;
