import React, { useState } from 'react';
import { Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { FooterContainer, FooterLegal, FooterSpacer, HeaderContainer, SkipLink, SubheaderContainer } from './app.component.style';
import { Body, H1, H2 } from '..';
import logo from '../../assets/images/logo.svg';
import { HOME_PAGE, LOGIN_PAGE, LOGOUT_PAGE, SSO_PAGE } from '../../config/constants.config';
import useResponsive from '../../hooks/useResponsive';
import { getSsoAuthKey } from '../../utils/session.util';
import { LogoutModal } from '../../components/modal/logout.modal';
import { useAuth } from '../../features/auth/auth.hook';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { loggedIn, session } = useAuth();
  // const { isMediumScreen } = useResponsive();
  const currentRoute = window.location.pathname;
  const noHeaderLink = currentRoute === SSO_PAGE || currentRoute === LOGOUT_PAGE;
  const [modalVisible, setModalVisible] = useState(false);

  const handleNavigation = (): void => {
    currentRoute !== HOME_PAGE ? navigate(HOME_PAGE) : window.location.reload();
  };

  const handleLogout = (): void => {
    if (getSsoAuthKey() === 'true') {
      setModalVisible(true);
    } else {
      navigate(LOGIN_PAGE, { replace: true });
    }
  };

  return (
    <>
      <HeaderContainer>
        <SkipLink href='#main-content'>Skip to main content</SkipLink>
        <Navbar expand style={{ padding: '24px 0' }}>
          <Flexbox flexDirection='column' justifyContent='center' alignItems='flex-start'>
            <Navbar.Brand>
              <a
                id="HomeButton"
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', pointerEvents: noHeaderLink ? 'none' : 'auto' }}
                tabIndex={noHeaderLink ? -1 : 0}
                onClick={handleNavigation}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleNavigation();
                  }
                }}
              >
                <img src={logo} style={{ marginRight: 12 }} width={95} alt={'BECU logo'} />
              </a>
            </Navbar.Brand>
          </Flexbox>
          {/* {(!isMediumScreen && currentRoute === HOME_PAGE) && <BodyBold style={{ marginLeft: 24 }}>Welcome to your personalized financial health guidance dashboard</BodyBold>} */}
          {(session && loggedIn) &&
            <Nav style={{ marginLeft: 'auto' }}>
              <Nav.Link id="NavBarLogOutLink" onClick={handleLogout}>
                Log Out
              </Nav.Link>
            </Nav>
          }
        </Navbar>
      </HeaderContainer>
      <LogoutModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

interface SubheaderProps {
  title: string;
  subtitle?: string;
  centered?: boolean;
}

export const Subheader: React.FC<SubheaderProps> = ({ title, subtitle, centered = false }: SubheaderProps) => {
  return (
    <SubheaderContainer>
      <Container>
        <H1 style={{ fontSize: subtitle ? 16 : '2.25rem', textAlign: centered ? 'center' : 'left' }} color='white'>{title}</H1>
        {subtitle !== '' && <H2 color='white' style={{ textAlign: centered ? 'center' : 'left' }}>{subtitle}</H2>}
      </Container>
    </SubheaderContainer>
  );
};

export const Footer: React.FC = () => {
  const { isLargeScreen } = useResponsive();

  return (
    <>
      <FooterSpacer />
      <Flexbox justifyContent='center' alignItems='center'>
        <FooterContainer>
          <Container role='contentinfo'>
            <Row>
              <FooterLegal md={6} big={isLargeScreen}>
                <Body>&#169; {new Date().getFullYear()} BECU. All Rights Reserved.</Body>
                <Body>Federally Insured by NCUA.</Body>
              </FooterLegal>
            </Row>
          </Container>
        </FooterContainer>
      </Flexbox>
    </>
  );
};