import { HUB_API } from '../../config/api.config';
import { WEEKLY_SUMMARY_ROUTE } from '../../config/constants.config';

class InsightsService {
  getWeeklySummary(customerId: string, token: string): any {
    return HUB_API.get(WEEKLY_SUMMARY_ROUTE, { headers: { Authorization: `Bearer ${token}` }, params: { customerId }});
  }
}

export default new InsightsService();
