import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AccountService from './account.service';
import type Account from '../../models/account.model';
import { type RootState } from '../../app/store';

interface AccountState {
  accounts: Account[];
}

const initialState: AccountState = {
  accounts: [],
};

export const getAccounts = createAsyncThunk('account/getAccounts', async (accountType: number, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const token = state.auth.session;
  const res = await AccountService.getAccounts(token, accountType);
  return res.data.accountInformation;
});

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload;
    })
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.accounts = [];
    })
  },
});

export const selectAccounts = (state: RootState): Account[] => state.account.accounts;

export default accountSlice.reducer;
