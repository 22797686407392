import { AUTH_API } from '../../config/api.config';
import { LOGIN_ROUTE, LOGIN_TOKEN_ROUTE } from '../../config/constants.config';

class AuthService {
  login(userId: string, password: string): any {
    return AUTH_API.post(LOGIN_ROUTE, { userId, password });
  };

  loginSSO(token: string, subscriberCode: string): any {
    return AUTH_API.post(LOGIN_TOKEN_ROUTE, { token, subscriberCode });
  };
}

export default new AuthService();
