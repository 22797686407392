import { useCallback } from 'react';
import { login, selectLoggedIn, selectAuthError, selectStatus, loginSSO, selectSession, AUTH_EVENTS, setLoggedIn } from './auth.slice';
import { useAppDispatch, useAppSelector } from '../core/store.hook';
import { getUser } from '../user/user.slice';
import { useNavigate } from 'react-router-dom';
import { HOME_PAGE, LOGIN_TIME_KEY } from '../../config/constants.config';
import { setSsoAuthKey } from '../../utils/session.util';
import dayjs from 'dayjs';

interface UseAuthReturnType {
  _login: (userId: string, password: string) => void;
  _loginSSO: (token: string, subscriberCode: string) => void;
  _loginToken: () => void;
  _logout: () => void;
  loggedIn: boolean;
  session: string | null;
  authError: number | null;
  status: string;
}

export function useAuth(): UseAuthReturnType {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(selectLoggedIn);
  const session = useAppSelector(selectSession);
  const authError = useAppSelector(selectAuthError);
  const status = useAppSelector(selectStatus);
  const navigate = useNavigate();

  const _login = useCallback((userId: string, password: string) => {
    void dispatch(login({ userId, password })).then((action) => {
      if (login.fulfilled.match(action)) {
        void dispatch(getUser()).then((action) => {
          if (getUser.fulfilled.match(action)) {
            setSsoAuthKey('false');
            sessionStorage.setItem(LOGIN_TIME_KEY, dayjs().toISOString());
            navigate(HOME_PAGE);
          }
        });
      }
    });
  }, [dispatch]);

  const _loginSSO = useCallback((token: string, subscriberCode: string) => {
    void dispatch(loginSSO({ token, subscriberCode })).then((action) => {
      if (loginSSO.fulfilled.match(action)) {
        void dispatch(getUser()).then((action) => {
          if (getUser.fulfilled.match(action)) {
            setSsoAuthKey('true');
            sessionStorage.setItem(LOGIN_TIME_KEY, dayjs().toISOString());
            navigate(HOME_PAGE, { replace: true });
          }
        });
      }
    });
  }, [dispatch]);

  const _loginToken = useCallback(() => {
    setLoggedIn();
    void dispatch(getUser());
  }, [dispatch]);

  const _logout = useCallback(() => {
    dispatch({ type: AUTH_EVENTS.LOGOUT });
    sessionStorage.clear();
  }, [dispatch]);

  return { _login, _loginSSO, _loginToken, _logout, loggedIn, session, authError, status };
}
