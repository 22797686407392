import React, { type ReactElement } from 'react';
import {
  LinkButton,
  PrimaryButton,
  SecondaryButton
} from './button.component.style';
import { type ButtonProps } from 'react-bootstrap';

interface BECUButtonProps extends ButtonProps {
  variant?: 'primary' | 'secondary' | 'link';
  marginTop?: string;
  autoWidth?: boolean;
}

const variantComponentMap = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  link: LinkButton,
};

export const Button = ({
  variant = 'primary',
  autoWidth = false,
  children,
  ...props
}: BECUButtonProps): ReactElement => {
  const ButtonComponent = variantComponentMap[variant];

  return (
    <ButtonComponent {...props} variant={variant} autoWidth={autoWidth}>
      {children}
    </ButtonComponent>
  );
};
