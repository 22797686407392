import { HUB_API } from '../../config/api.config';
import { ACCOUNT_ROUTE } from '../../config/constants.config';

class AccountService {
  getAccounts(token: string, accountType: number): any {
    return HUB_API.get(ACCOUNT_ROUTE, { headers: { Authorization: `Bearer ${token}` }, params: { AccountType: accountType } });
  }
}

export default new AccountService();
