import Flexbox from 'flexbox-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, ContentContainer } from './weekly-summary.styles';
import { useInsights } from '../../features/insights/insights.hook';
import { Body, Button, H3 } from '../../components/index';
import { HOME_PAGE } from '../../config/constants.config';
import { useUser } from '../../features/user/user.hook';
import { useAppDispatch } from '../../features/core/store.hook';
import { getWeeklySummary } from '../../features/insights/insights.slice';

const WeeklySummary = (): any => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { weeklySummaryUrl, weeklySummaryStatus } = useInsights();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (weeklySummaryStatus === 'idle' && currentUser) {
      void dispatch(getWeeklySummary());
    }
  }, [weeklySummaryStatus, currentUser]);

  const handleOnLoad = (): void => {
    setIsLoading(false);
  };

  return (
    <Flexbox style={{ height: '100%', width: '100%', position: 'relative' }}>
      {(isLoading && weeklySummaryUrl) &&
        <ContentContainer>
          <Spinner className='spinner' />
        </ContentContainer>
      }
      {(weeklySummaryUrl) ? (
        <iframe
          src={weeklySummaryUrl}
          style={{ flexGrow: 1, height: '100%', width: '100%' }}
          onLoad={handleOnLoad}
        />
      ) : (
        <ContentContainer className='text-center'>
          <H3>No Weekly Summary Available</H3>
          <Body className='px-5'>It looks like you do not have a weekly summary for this week.</Body>
          <Button autoWidth className='mt-3' onClick={() => { navigate(HOME_PAGE); }}>
            Return to home
          </Button>
        </ContentContainer>
      )}
    </Flexbox>
  );
};

export default WeeklySummary;
