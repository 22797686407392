import Flexbox from 'flexbox-react';
import React, { type ReactElement, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import loggedOutImage from '../../assets/images/logged-out.svg';
import { useAuth } from '../../features/auth/auth.hook';
import { Subheader } from '../../components/index';
import { LogoutContainer, LogoutImage } from './logout.styles';

const Logout = (): ReactElement => {
  const { _logout } = useAuth();

  useEffect(() => {
    _logout();
  }, []);

  return (
    <Flexbox flexDirection={'column'}>
      <Subheader title={'Logged Out'} centered />
      <LogoutContainer>
        <Row>
          <Col md={{span: 8, offset: 2}} style={{ paddingTop: 30, paddingBottom: 30, textAlign: 'center' }}>
            <LogoutImage
              src={loggedOutImage}
              aria-hidden='true'
            />
            <h4 style={{margin: '2rem 0 1rem'}}>
              We securely ended your Financial Health Pilot session.
            </h4>
            <p style={{fontSize: 14}}>
              You can now close this window.
            </p>
          </Col>
        </Row>
      </LogoutContainer>
    </Flexbox>
  );
};

export default Logout;
