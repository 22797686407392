import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';

export const LogoutContainer = styled(Container)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoutImage = styled('img')`
  min-width: 40px;
  vertical-align: middle;
  border-style: none;
`;