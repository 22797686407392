import type { Theme } from '@emotion/react';

const CommonTheme: Theme = {
  borderRadius: {
    xSmall: 6,
    small: 8,
    medium: 10,
    large: 12,
    xLarge: 14,
  },
  color: {
    becuRed: '#D12A2E',
    harbor: '#007C89',
    ocean: '#006979',
    obsidian: '#192838',
    stone: '#4D5F69',
    snow: '#FFFFFF',
    cloud: '#F4F5f5',
    rain: '#EAEBEC',
    storm: '#D5D7D9',
    success: '#33C677',
    successLight: '#D9F7EA',
    error: '#AB111A',
    errorLight: '#F5D9DB',
    amber: '#FFA300',
    amberLight: '#FFECCC',
    darkTeal: '#005460',
    actionBlue: '#4470D6',
    actionBlueLight: '#D7E4F4',
    label: '#0D141C',
  },
  iconSize: {
    small: 16,
    medium: 24,
    large: 32,
  },
};

export default CommonTheme;
