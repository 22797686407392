import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import InsightsService from './insights.service';
import { type RootState } from '../../app/store';
import { type API_CONSTANTS } from '../../config/constants.config';

interface InsightsState {
  weeklySummaryUrl: string | null;
  weeklySummaryStatus: API_CONSTANTS['STATUS'];
}

const initialState: InsightsState = {
  weeklySummaryUrl: null,
  weeklySummaryStatus: 'idle',
};

export const getWeeklySummary = createAsyncThunk('insights/getWeeklySummary', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const token = state.auth.session;
  const customerId = state.user.currentUser.budCustomerId;
  const res = await InsightsService.getWeeklySummary(customerId, token);
  return res.data;
})

const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWeeklySummary.fulfilled, (state, action) => {
      state.weeklySummaryUrl = action.payload.data.url;
      state.weeklySummaryStatus = 'fulfilled';
    })
    builder.addCase(getWeeklySummary.pending, (state) => {
      state.weeklySummaryStatus = 'pending';
    })
    builder.addCase(getWeeklySummary.rejected, (state) => {
      state.weeklySummaryUrl = null;
      state.weeklySummaryStatus = 'rejected';
    })
  },
});

export const selectWeeklySummaryUrl = (state: RootState): string | null => state.insights.weeklySummaryUrl;
export const selectWeeklySummaryStatus = (state: RootState): API_CONSTANTS['STATUS'] => state.insights.weeklySummaryStatus;

export default insightsSlice.reducer;
