export const HUB_SERVICE_URL = `${process.env.REACT_APP_HUB_API_URL ?? ''}/api/V1`;
export const LOGIN_SERVICE_URL = `${process.env.REACT_APP_LOGIN_SERVICE_URL ?? ''}/api/V1`;

export const TOKEN_TIME = Number(process.env.REACT_APP_TOKEN_TIME);
export const MAX_SESSION_MODAL_TIME = Number(process.env.REACT_APP_MAX_SESSION_MODAL_TIME);

export const ACCOUNT_ROUTE = '/Account'
export const INSIGHT_ROUTE = '/Insight';
export const LOGIN_ROUTE = '/authentication/signon';
export const LOGIN_TOKEN_ROUTE = '/authentication/signontoken';
export const USER_ROUTE = '/user';
export const WEEKLY_SUMMARY_ROUTE = `${INSIGHT_ROUTE}/weeklysummary`;

export const EMERGENCY_ENVELOPE_PAGE = '/emergency-envelope';
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const LOGOUT_PAGE = '/logout';
export const NOT_FOUND_PAGE = '/not-found';
export const SSO_PAGE = '/sso';
export const TRANSFERS_PAGE = '/transfer';
export const WEEKLY_SUMMARY_PAGE = '/weekly-summary';

export interface API_CONSTANTS {
  STATUS: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

export const TOKEN_STORAGE_KEY = 'token';
export const SESSION_STATE_KEY = 'state';
export const SSO_AUTH_KEY = 'sso_auth';
export const LOGIN_TIME_KEY = 'login_time';

export const FINANCIAL_HEALTH_HUB_SUBSCRIBER_CODE = 5;

export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_LINK;
export const ENVELOPES_URL = process.env.REACT_APP_ENVELOPES_LINK;
export const RETURN_TO_OLB_URL = process.env.REACT_APP_RETURN_TO_OLB_LINK;

export const ACCOUNT_MAJOR_CODE = {
  SAVINGS: 'SAV',
  CHECKING: 'CK',
};

export const ACCOUNT_TYPES = {
  ALL: 0,
  CHECKING: 1,
  SAVINGS: 2,
}
