import { useTheme } from '@emotion/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import { Activity, ChevronLeft, ChevronRight, GraphDownArrow, JournalText } from 'react-bootstrap-icons';

import useResponsive from '../../hooks/useResponsive';
import WeeklySummary from '../../components/dashboard/weekly-summary.component';
import { H2 } from '../../components/index';
import { useUser } from '../../features/user/user.hook';
// import type Insight from '../../models/insight.model';

// const exampleInsights: Insight[] = [
//   {
//     id: '1',
//     title: 'Cannot Cover Bills',
//     body: 'Your BECU checking account is currently at $213. Your car loan is due in 3 days, transfer money into your checking account now.',
//     details: '',
//     description: 'Check what bills need urgent attention.',
//     actions: [
//       {
//         title: 'View More Details',
//         type: 'secondary',
//         link: '',
//       },
//       {
//         title: 'Transfer Money',
//         type: 'primary',
//         link: '/transfer'
//       },
//     ],
//     type: 'Low Funds',
//     tags: [
//       {
//         title: 'Urgent',
//         type: 'warn',
//       },
//       {
//         title: 'New',
//         type: 'success',
//       },
//     ],
//   },
//   {
//     id: '2',
//     title: 'Essential Spending',
//     body: 'Your BECU checking account is currently at $213. Your car loan is due in 3 days, transfer money into your checking account now.',
//     details: '',
//     description: 'Summary of your essential spending.',
//     actions: [
//       {
//         title: 'View More Details',
//         type: 'secondary',
//         link: '',
//       },
//       {
//         title: 'Transfer Money',
//         type: 'primary',
//         link: '/transfer'
//       },
//     ],
//     type: 'Spending',
//     tags: [
//       {
//         title: 'New',
//         type: 'success',
//       },
//     ],
//   },
//   {
//     id: '3',
//     title: 'Non-Esential Spending',
//     body: 'Your BECU checking account is currently at $213. Your car loan is due in 3 days, transfer money into your checking account now.',
//     details: '',
//     description: 'Summary of your non-essential spending.',
//     actions: [
//       {
//         title: 'View More Details',
//         type: 'secondary',
//         link: '',
//       },
//       {
//         title: 'Transfer Money',
//         type: 'primary',
//         link: '/transfer'
//       },
//     ],
//     type: 'Spending',
//     tags: [
//       {
//         title: 'New',
//         type: 'success',
//       },
//     ],
//   },
//   {
//     id: '4',
//     title: 'Income Finder',
//     body: 'Your BECU checking account is currently at $213. Your car loan is due in 3 days, transfer money into your checking account now.',
//     details: '',
//     description: 'A list of your different streams of income.',
//     actions: [
//       {
//         title: 'View More Details',
//         type: 'secondary',
//         link: '',
//       },
//       {
//         title: 'Transfer Money',
//         type: 'primary',
//         link: '/transfer'
//       },
//     ],
//     type: 'General',
//     tags: [
//       {
//         title: 'New',
//         type: 'success',
//       },
//     ],
//   },
// ];

// const exampleHelp = [
//   'Financial Guidance',
//   'Financial Webinars',
//   'Investment Articles',
//   'Investment Calculators',
//   'Credit & Debt Counseling',
// ];

// const exampleResources = [
//   'BECU Envelopes',
//   'Save Up',
//   'Financial Goals & Resource Guide',
//   'Financial Health Check',
//   'Zogo',
// ];

const Home = (): any => {
  const theme = useTheme();
  const { currentUser } = useUser();
  const { isXSScreen } = useResponsive();
  // const [selectedInsight, setSelectedInsight] = useState<Insight>(exampleInsights.length ? exampleInsights[0] : { id: '0', title: '', body: '', details: '', description: '', actions: [], type: '', tags: [] });
  // const insightsPerView = isLargeScreen ? (isSmallScreen ? 1 : 2) : 3;

  // const groupedInsights = exampleInsights.reduce((result: any, item, index) => {
  //   const groupIndex = Math.floor(index / insightsPerView);
  //   if (!result[groupIndex]) {
  //     result[groupIndex] = [];
  //   }
  //   result[groupIndex].push(item);
  //   return result;
  // }, []);

  // const insightTypeIcon = (type: string): any => {
  //   switch (type) {
  //     case 'Spending':
  //       return <Activity />
  //     case 'Low Funds':
  //       return <GraphDownArrow />
  //     case 'General':
  //     default:
  //       return <JournalText />
  //   }
  // };

  // const handleInsightAction = (link: string): void => {
  //   if (link !== '' && link[0] === '/') {
  //     navigate(link);
  //   } else {
  //     console.log(link);
  //   }
  // };

  // const handleInsightSelection = (insight: Insight): void => {
  //   // TODO: API call to update status to VIEWED if insight is NEW
  //   setSelectedInsight(insight);
  // };

  return (
    <div style={{ padding: !isXSScreen ? '64px' : '32px 24px', backgroundColor: theme.color.cloud, flexGrow: 1 }}>
      {currentUser && <H2 className='mb-4'>Welcome, {currentUser.firstName}</H2>}
      <Row>
        <Col style={{ display: 'flex', justifyContent: !isXSScreen ? 'flex-start' : 'center' }}>
          <WeeklySummary />
        </Col>
        {/* <Col lg={8}>
          <ComponentContainer className='d-flex flex-column justify-content-between'>
            <div>
              <Flexbox flexDirection={!isLargeScreen ? 'row' : 'column'} alignItems={!isLargeScreen ? 'center' : 'flex-start'} justifyContent='space-between' style={{ gap: !isLargeScreen ? 0 : 24 }}>
                <H3>Your Insights</H3>
                <Flexbox alignItems='center' style={{ gap: 20 }}>
                  <Flexbox alignItems='center' style={{ gap: 6 }}>
                    {insightTypeIcon(selectedInsight.type)}
                    <BodySmallBold style={{ fontWeight: 700 }}>{selectedInsight.type}</BodySmallBold>
                  </Flexbox>
                  {selectedInsight.tags.map((tag, i) => (
                    <Pill key={i} variant={tag.type} title={tag.title} />
                  ))}
                </Flexbox>
              </Flexbox>
              <H3 style={{ marginTop: 24 }}>{selectedInsight.title}</H3>
              <Body style={{ marginTop: 24 }}>{selectedInsight.body}</Body>
              <Flexbox flexDirection={!isMediumScreen ? 'row' : 'column'} justifyContent={!isMediumScreen ? 'flex-start' : 'center'} style={{ marginTop: 24, gap: 24 }}>
                {selectedInsight.actions.map((action, i) => (
                  <Button key={i} variant={action.type} style={{ width: !isMediumScreen ? 'fit-content' : '100%' }} onClick={() => { handleInsightAction(action.link) }}>
                    {action.title}
                  </Button>
                ))}
              </Flexbox>
            </div>
            <hr style={{ margin: '2rem 0' }} />
            <Carousel controls indicators={false} interval={null} wrap={false} touch prevIcon={<ChevronLeft color='#0D141C' size={24} />} nextIcon={<ChevronRight color='#0D141C' size={24} />}>
              {groupedInsights.map((group, i) => (
                <Carousel.Item key={i}>
                  <Row className='d-flex px-5'>
                    {group.map((item, itemIndex) => (
                      <Col key={itemIndex} xs={12} md={12 / insightsPerView}>
                        <InsightCard
                          selected={selectedInsight.id === item.id}
                          tabIndex={0}
                          onClick={() => { handleInsightSelection(item); }}
                          onKeyDown={(e) => { if (e.key === 'Enter') handleInsightSelection(item); }}
                        >
                          <Card.Body>
                            <Flexbox alignItems='center' style={{ gap: 6 }}>
                              {item.tags.map((tag, tagIndex) => (
                                <Pill key={tagIndex} variant={tag.type} title={tag.title} />
                              ))}
                            </Flexbox>
                            <Flexbox alignItems='center' style={{ gap: 6, marginTop: 6 }}>
                              {insightTypeIcon(item.type)}
                              <BodySmallBold style={{ fontWeight: 700 }}>{item.type}</BodySmallBold>
                            </Flexbox>
                            <BodyBold style={{ marginTop: 6, fontWeight: 600 }}>{item.title}</BodyBold>
                            <BodySmall style={{ fontSize: 10 }} color={theme.color.stone}>{item.description}</BodySmall>
                          </Card.Body>
                        </InsightCard>
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </ComponentContainer>
        </Col> */}
        {/* <Col lg={4} className={`${!isMediumScreen ? 'mt-0' : 'mt-4'}`}>
          <ComponentContainer>
            <H3>Financial Calendar</H3>
            <Body>Partner with Bud to use iframed calendar component to show members past, present, and forecasted income, expenses, bill due dates, etc.</Body>
          </ComponentContainer>
        </Col> */}
      </Row>
      {/* <Row className='mt-4'>
        <Col lg={8}>
          <ComponentContainer height='544px' smallHeight='396px'>
            <H3>Bud Data Visualizations</H3>
            <Body>Partner with Bud to use iframed experience(s) to show data graphs / larger visualizations for members</Body>
          </ComponentContainer>
        </Col>
        <Col lg={4} className={`${!isMediumScreen ? 'mt-0' : 'mt-4'}`}>
          <ComponentContainer height='544px' smallHeight='407px'>
            <H3>Bud Widgets</H3>
            <Body>Partner with Bud to use iframed experience(s) to show widgets to compare income & expenses and other smaller visuals </Body>
          </ComponentContainer>
        </Col>
      </Row> */}
      {/* <Row className='mt-4'>
        <Col lg={6} xl={3}>
          <ComponentContainer height='290px' smallHeight='263px'>
            <H3>More Help</H3>
            <Flexbox flexDirection='column'>
              {exampleHelp.map((help, i) => (
                <Button
                  key={i}
                  variant='link'
                  onClick={() => { console.log(help); }}
                  className='mt-3'
                >
                  {help}
                </Button>
              ))}
            </Flexbox>
          </ComponentContainer>
        </Col>
        <Col lg={6} xl={3} className={`${!isMediumScreen ? 'mt-0' : 'mt-4'}`}>
          <ComponentContainer height='290px' smallHeight='263px'>
            <H3>Resources</H3>
            <Flexbox flexDirection='column'>
              {exampleResources.map((resource, i) => (
                <Button
                  key={i}
                  variant='link'
                  onClick={() => { console.log(resource); }}
                  className='mt-3'
                >
                  {resource}
                </Button>
              ))}
            </Flexbox>
          </ComponentContainer>
        </Col>
        <Col lg={12} xl={6} className={`${!isLargeScreen ? 'mt-0' : 'mt-4'}`}>
          <ComponentContainer height='290px' smallHeight='263px'>
            <H3>We&apos;d Love to Hear From You</H3>
            <Body>Utilize Medallia here to get member feedback on the application / insights</Body>
          </ComponentContainer>
        </Col>
      </Row> */}
    </div>
  );
};

export default Home;
