import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import {
  // EMERGENCY_ENVELOPE_PAGE,
  HOME_PAGE, LOGIN_PAGE,
  LOGIN_TIME_KEY,
  LOGOUT_PAGE,
  MAX_SESSION_MODAL_TIME,
  NOT_FOUND_PAGE,
  RETURN_TO_OLB_URL,
  SSO_PAGE,
  TOKEN_TIME,
  // TRANSFERS_PAGE,
  WEEKLY_SUMMARY_PAGE
} from './config/constants.config';
import { useAuth } from './features/auth/auth.hook';
import { useUser } from './features/user/user.hook';
import MainLayout from './layout/main-layout';
import { SessionWarningModal } from './components/modal/session-warning.modal';

import Components from './pages/Components/component.page';
// import EmergencyEnvelope from './pages/EmergencyEnvelope/emergency-envelope.page';
import Home from './pages/Home/home.page';
import Login from './pages/Login/login.page';
import Logout from './pages/Logout/logout.page';
import SSO from './pages/SSO/sso.page';
// import Transfer from './pages/Transfer/transfer.page';
import WeeklySummary from './pages/WeeklySummary/weekly-summary.page';
import NotFound from './pages/NotFound/not-found.page';

const SKIP_PAGES = [LOGIN_PAGE, SSO_PAGE, LOGOUT_PAGE];
const SESSION_DURATION = TOKEN_TIME * 1000 * 60;
const WARNING_DURATION = MAX_SESSION_MODAL_TIME * 1000 * 60;

const App = (): any => {
  const location = useLocation();
  const navigate = useNavigate();
  const { _loginToken, loggedIn, session } = useAuth();
  const { currentUser } = useUser();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const warningTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const logoutTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!SKIP_PAGES.includes(location.pathname) && !currentUser && session) {
      _loginToken();
    }
  }, [loggedIn, session]);

  useEffect(() => {
    const loginTime = sessionStorage.getItem(LOGIN_TIME_KEY);

    if (!loginTime && !SKIP_PAGES.includes(location.pathname)) {
      // If no login time is found, navigate to OLB
      window.open(RETURN_TO_OLB_URL, '_self');
      return;
    }

    if (loginTime) {
      const loginTimestamp = dayjs(loginTime).valueOf();
      const currentTime = dayjs().valueOf();
      const elapsedTime = currentTime - loginTimestamp;

      clearTimeout(warningTimeoutRef.current as NodeJS.Timeout);
      clearTimeout(logoutTimeoutRef.current as NodeJS.Timeout);

      if (elapsedTime >= SESSION_DURATION && !SKIP_PAGES.includes(location.pathname)) {
        handleLogout();
      } else {
        const timeToWarning = WARNING_DURATION - elapsedTime;
        if (timeToWarning > 0) {
          warningTimeoutRef.current = setTimeout(() => {
            setShowWarningModal(true);
          }, timeToWarning);
        }

        const timeToLogout = SESSION_DURATION - elapsedTime;

        if (timeToLogout) {
          logoutTimeoutRef.current = setTimeout(() => {
            setShowWarningModal(false);
            handleLogout();
          }, timeToLogout);
        }
      }
    }
  }, [loggedIn, navigate]);

  const handleLogout = (): void => {
    clearTimeout(warningTimeoutRef.current as NodeJS.Timeout);
    clearTimeout(logoutTimeoutRef.current as NodeJS.Timeout);
    navigate(LOGOUT_PAGE);
  };

  return (
    <MainLayout>
      <Routes>
        {(process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'stage') && <Route path={'/components'} element={<Components />} />}
        {/* <Route path={EMERGENCY_ENVELOPE_PAGE} element={<EmergencyEnvelope />} /> */}
        {(process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'stage') && <Route path={LOGIN_PAGE} element={<Login />} />}
        <Route path={LOGOUT_PAGE} element={<Logout />} />
        <Route path={HOME_PAGE} element={<Home />} />
        <Route path={SSO_PAGE} element={<SSO />} />
        {/* <Route path={TRANSFERS_PAGE} element={<Transfer />} /> */}
        <Route path={WEEKLY_SUMMARY_PAGE} element={<WeeklySummary />} />
        <Route path={NOT_FOUND_PAGE} element={<NotFound />} />
        <Route path={'*'} element={<Navigate to={NOT_FOUND_PAGE} />} />
      </Routes>
      <SessionWarningModal
        modalVisible={showWarningModal && location.pathname !== LOGOUT_PAGE}
        setModalVisible={setShowWarningModal}
      />
    </MainLayout>
  );
};

export default App;
