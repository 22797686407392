import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from '../utils/session.util';
import accountReducer from '../features/account/account.slice';
import authReducer, { AUTH_EVENTS } from '../features/auth/auth.slice';
import insightsReducer from '../features/insights/insights.slice';
import userReducer from '../features/user/user.slice';

const combinedReducer: any = combineReducers({
  account: accountReducer,
  auth: authReducer,
  insights: insightsReducer,
  user: userReducer,
});

const rootReducer = (state, action): any => {
  if (action.type === AUTH_EVENTS.LOGOUT) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistentState = { ...loadState() };

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(),
  reducer: rootReducer,
  preloadedState: persistentState,
});

store.subscribe(() => {
  if (store.getState().auth !== persistentState?.auth) {
    saveState(store.getState().auth);
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
