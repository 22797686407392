import { Header } from '../components/index';
import React, { useEffect, useRef, useState, type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const isLoginPage = location.pathname === '/login';

  useEffect(() => {
    const updateHeaderHeight = (): void => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    updateHeaderHeight();

    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <>
      <div ref={headerRef}>
        {!isLoginPage && <Header />}
      </div>
      <main id="main-content" style={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - ${headerHeight}px)` }}>{children}</main>
    </>
  )
};

export default MainLayout;