import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import {Col, Row} from 'react-bootstrap';

import { HOME_PAGE, LOGIN_PAGE, RETURN_TO_OLB_URL } from '../../config/constants.config';
import notFoundImage from '../../assets/images/app/not-found.svg';
import useResponsive from '../../hooks/useResponsive';
import { Body, Button, Subheader } from '../../components/index';
import { NotFoundContainer, NotFoundImage } from './not-found.styles';
// import PageMetaData from 'components/app/PageMetaData';

const NotFound = (): any => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isXSScreen } = useResponsive();
  const loginRedirect = location.pathname === LOGIN_PAGE;

  const handleReturnButton = (): void => {
    if (loginRedirect) {
      window.open(RETURN_TO_OLB_URL, '_self');
    } else {
      navigate(HOME_PAGE);
    }
  };

  return (
    <Flexbox flexDirection={'column'}>
      {/* <PageMetaData pageTitle={'Page Not Found | BECU'} /> */}
      <Subheader title={'Page Not Found'} centered />
      <NotFoundContainer role={'main'} id="main-content">
        <Row>
          <Col style={{ paddingTop: 40, paddingBottom: 40, textAlign: 'center' }}>
            <NotFoundImage
              src={notFoundImage}
              alt={'Page not found'}
              largeImage={!isXSScreen}
            />
            <Body style={{ fontSize: 18, fontWeight: 500, marginTop: 24, marginBottom: 24 }}>It looks like your page cannot be found.</Body>
            <Flexbox flexDirection='column'>
              <Button id="ReturnHomeButton" variant={'primary'} style={{ width: 'auto' }} onClick={handleReturnButton}>
                Return {loginRedirect ? 'to Online Banking' : 'Home'}
              </Button>
              {/* {!loginRedirect &&
                <Button id="FAQButton" variant={'secondary'} style={{ width: 'auto', marginTop: 16 }} onClick={() => {}} aria-label="Frequently asked questions and tips">
                  FAQs and Tips
                </Button>
              } */}
            </Flexbox>
          </Col>
        </Row>
      </NotFoundContainer>
    </Flexbox>
  );
};

export default NotFound;
