import { type API_CONSTANTS } from '../../config/constants.config';
import { useAppSelector } from '../core/store.hook';
import { selectWeeklySummaryStatus, selectWeeklySummaryUrl } from './insights.slice';

interface UseInsightsReturnType {
  weeklySummaryUrl: string | null;
  weeklySummaryStatus: API_CONSTANTS['STATUS'];
};

export function useInsights(): UseInsightsReturnType {
  const weeklySummaryUrl = useAppSelector(selectWeeklySummaryUrl);
  const weeklySummaryStatus = useAppSelector(selectWeeklySummaryStatus);

  return { weeklySummaryUrl, weeklySummaryStatus };
}