import Flexbox from 'flexbox-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../features/core/store.hook';
import { getWeeklySummary } from '../../features/insights/insights.slice';
import { ComponentContainer } from './dashboard.components.style';
import { WEEKLY_SUMMARY_PAGE } from '../../config/constants.config';
import { Body, Button, H3 } from '../../components/index';
import { useInsights } from '../../features/insights/insights.hook';
import { useUser } from '../../features/user/user.hook';

const WeeklySummary: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { weeklySummaryUrl, weeklySummaryStatus } = useInsights();

  useEffect(() => {
    if (weeklySummaryStatus === 'idle' && currentUser) {
      void dispatch(getWeeklySummary());
    }
  }, [weeklySummaryStatus, currentUser]);

  if (weeklySummaryUrl === null) {
    return (
      <ComponentContainer>
        <H3 className='mb-0'>No Weekly Summary Available</H3>
        <Body>It looks like you do not have a weekly summary for this week.</Body>
        <img src={require('../../assets/icons/summary-calendar.png')} width={68} height={67} className='align-self-end' aria-hidden />
      </ComponentContainer>
    );
  }

  return (
    <ComponentContainer>
      <H3 className='mb-0'>Your Weekly Summary is Here</H3>
      <Body>Discover your spending insights from the past week.</Body>
      <Flexbox flexDirection='row' alignItems='center' justifyContent='space-between'>
        <Button onClick={() => { navigate(WEEKLY_SUMMARY_PAGE); }} autoWidth>
          View Summary
        </Button>
        <img src={require('../../assets/icons/summary-calendar.png')} width={68} height={67} aria-hidden />
      </Flexbox>
    </ComponentContainer>
  );
};

export default WeeklySummary;