import styled from '@emotion/styled';

export const BasePill = styled('div')`
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 14px;
  border-radius: 80px;
`;

export const SuccessPill = styled(BasePill)`
  background-color: #E4F9E9;
  border: 2px solid #238752;
`;

export const WarnPill = styled(BasePill)`
  background-color: #FDEAE9;
  border: 2px solid #BB1E22;
`;
