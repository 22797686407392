import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';

export const SSOContainer = styled(Container)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled('div')`
  color: ${({ theme }) => theme.color.obsidian};
  width: 3rem;
  height: 3rem;
`;
