 import React, { type ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Body, Button } from '../index';
import { LOGOUT_PAGE } from '../../config/constants.config';
import useResponsive from '../../hooks/useResponsive';

interface LogoutModalProps {
  modalVisible: boolean;
  setModalVisible: (boolean) => void;
}

 export const LogoutModal = ({ modalVisible, setModalVisible }: LogoutModalProps): ReactElement => {
  const navigate = useNavigate();
  const { isXSScreen } = useResponsive();

  const handleCloseModal = (): void => {
    setModalVisible(false);
  };

  const handleLogout = (): void => {
    setModalVisible(false);
    navigate(LOGOUT_PAGE, { replace: true })
  };

  return (
    <Modal id='LogoutModal' show={modalVisible} onHide={handleCloseModal}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          Log Out
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body>Logging out of the Financial Health Pilot. Do you wish to continue?</Body>
      </Modal.Body>
      <Modal.Footer className="border-0" style={{ flexDirection: !isXSScreen ? 'row' : 'column' }}>
        <Button
          id='CancelLogoutButton'
          variant='secondary'
          onClick={handleCloseModal}
          style={{ width: isXSScreen ? '100%' : 'auto' }}
        >
          Cancel
        </Button>
        <Button
          id='ConfirmLogoutButton'
          onClick={handleLogout}
          style={{ width: isXSScreen ? '100%' : 'auto' }}
        >
          Yes, Log Out
        </Button>
      </Modal.Footer>
    </Modal>
  );
 }