import styled from '@emotion/styled';
import Flexbox from 'flexbox-react';
import { Alert } from 'react-bootstrap';

interface BodyContainerTypes {
  small: boolean;
}

export const BaseAlert = styled(Alert)`
  padding: 0;
  border-radius: 4px;
  color: ${({ theme }) => theme.color.obsidian};
  & .btn-close {
    color: ${({ theme }) => theme.color.obsidian};
  }
`;

export const DangerAlert = styled(BaseAlert)`
  border-color: ${({ theme }) => theme.color.error};
  background-color: ${({ theme }) => theme.color.errorLight};
  & svg {
    color: ${({ theme }) => theme.color.error};
  }
`;

export const WarningAlert = styled(BaseAlert)`
  border-color: ${({ theme }) => theme.color.amber};
  background-color: ${({ theme }) => theme.color.amberLight};
  & svg {
    color: ${({ theme }) => theme.color.amber};
  }
`;

export const SuccessAlert = styled(BaseAlert)`
  border-color: ${({ theme }) => theme.color.success};
  background-color: ${({ theme }) => theme.color.successLight};
  & svg {
    color: ${({ theme }) => theme.color.success};
  }
`;

export const InfoAlert = styled(BaseAlert)`
  border-color: ${({ theme }) => theme.color.actionBlue};
  background-color: ${({ theme }) => theme.color.actionBlueLight};
  & svg {
    color: ${({ theme }) => theme.color.actionBlue};
  }
`;

export const IconContainer = styled(Flexbox)`
  padding: 12px;
`;

export const BodyContainer = styled('div')<BodyContainerTypes>`
  padding: 16px;
  padding-top: ${({ small }) => small ? '4px' : '16px'};
  width: ${({ small }) => small ? '83%' : '100%'};
`;
