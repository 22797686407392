import { useCallback } from 'react';
import { getUser, selectCurrentUser } from './user.slice';
import { useAppDispatch, useAppSelector } from '../core/store.hook';
import type User from '../../models/user.model';

interface UseUserReturnType {
  currentUser: User;
  _getUser: () => void;
};

export function useUser(): UseUserReturnType {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);

  const _getUser = useCallback(() => {
    void dispatch(getUser());
  }, [dispatch]);

  return { currentUser, _getUser };
}