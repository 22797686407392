import styled from '@emotion/styled';
import { Form, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

export const FormLabel = styled(Form.Label)`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.label};
  margin-bottom: 8px;
`;

export const FormControl = styled(Form.Control)`
  border: ${({ theme }) => `1px solid ${theme.color.stone}`};
  border-radius: 2px;
  height: 44px;
  color: ${({ theme }) => theme.color.stone} !important;
  &:focus {
    box-shadow: 0 0 8px rgb(68 112 214 / 60%) !important;
    border: 2px solid ${({ theme }) => theme.color.actionBlue} !important;
    outline: 0;
  }
`;

export const FormDiv = styled('div')`
  border: ${({ theme }) => `1px solid ${theme.color.stone}`};
  border-radius: 2px;
  height: 44px;
  padding: 6px 12px;
  color: ${({ theme }) => theme.color.stone} !important;
  &:focus {
    box-shadow: 0 0 8px rgb(68 112 214 / 60%) !important;
    border: 2px solid ${({ theme }) => theme.color.actionBlue} !important;
    outline: 0;
  }
`;

export const FormCurrency = styled(CurrencyInput)`
  border: ${({ theme }) => `1px solid ${theme.color.stone}`};
  border-radius: 2px;
  height: 44px;
  padding: 6px 12px;
  color: ${({ theme }) => theme.color.stone} !important;
  &:focus {
    box-shadow: 0 0 8px rgb(68 112 214 / 60%) !important;
    border: 2px solid ${({ theme }) => theme.color.actionBlue} !important;
    outline: 0;
  }
`

export const FormSelect = styled(Form.Select)`
  border: ${({ theme }) => `1px solid ${theme.color.stone}`};
  border-radius: 2px;
  height: 44px;
  color: ${({ theme }) => theme.color.stone} !important;
  &:focus {
    box-shadow: 0 0 8px rgb(68 112 214 / 60%) !important;
    border: 2px solid ${({ theme }) => theme.color.actionBlue} !important;
    outline: 0;
  }
`;

export const InputGroupText = styled(InputGroup.Text)`
  color: ${({ theme }) => theme.color.stone} !important;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border: ${({ theme }) => `1px solid ${theme.color.stone}`};
  border-radius: 2px;
  &:focus-visible {
    z-index: 1;
  }
`;