// import Flexbox from 'flexbox-react';
import React, { useEffect, useRef, useState, type ReactElement} from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Body, BodyBold, Button } from '../index';
import useResponsive from '../../hooks/useResponsive';
import { getSsoAuthKey } from '../../utils/session.util';
import { LOGOUT_PAGE, MAX_SESSION_MODAL_TIME, RETURN_TO_OLB_URL, TOKEN_TIME } from '../../config/constants.config';
import { useAuth } from '../../features/auth/auth.hook';

interface SessionWarningModalProps {
  modalVisible: boolean;
  setModalVisible: (boolean) => void;
}

export const SessionWarningModal = ({ modalVisible, setModalVisible }: SessionWarningModalProps): ReactElement  => {
  const navigate = useNavigate();
  const { isXSScreen } = useResponsive();
  const { _logout } = useAuth();
  const [minutes, setMinutes] = useState(Math.floor(TOKEN_TIME - MAX_SESSION_MODAL_TIME));
  const [seconds, setSeconds] = useState(Number(((TOKEN_TIME - MAX_SESSION_MODAL_TIME) % 1).toFixed(1)) * 60);
  const [count, setCount] = useState((TOKEN_TIME - MAX_SESSION_MODAL_TIME) * 60000);
  const requestRef = useRef<any>();
  const previousTimeRef = useRef();

  const animateCountdown = (currentTime): void => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = currentTime - previousTimeRef.current;
      if (deltaTime > 1000 && count > 0) {
        setCount((prevCount) => {
          const newCount = prevCount - deltaTime;
          setMinutes(() => Math.floor(newCount / 60000));
          setSeconds(() => Math.floor((newCount / 1000) % 60));
          return newCount;
        });
        previousTimeRef.current = currentTime;
      }
    } else {
      previousTimeRef.current = currentTime;
    }
    requestRef.current = requestAnimationFrame(animateCountdown);
  };

  useEffect(() => {
    if (modalVisible) {
      requestRef.current = requestAnimationFrame(animateCountdown);
      return () => {
        cancelAnimationFrame(requestRef.current);
      }
    }
  }, [modalVisible]); 

  const handleLogout = (): void => {
    if (getSsoAuthKey() === 'true') {
      navigate(LOGOUT_PAGE, { replace: true });
    } else {
      window.open(RETURN_TO_OLB_URL, '_self');
      setTimeout(() => {
        _logout();
      }, 225);
    }
  };

  const handleCloseModal = (): void => {
    setModalVisible(false);
  };

  return (
    <Modal id={'SessionWarningModal'} show={modalVisible} onHide={handleCloseModal}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          Need More Time?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body style={{ marginBottom: '1rem' }}>Your session may not exceed the maximum duration.</Body>
        <BodyBold id="CountdownText">
          Session ending in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </BodyBold>
      </Modal.Body>
      <Modal.Footer className="border-0" style={{ flexDirection: !isXSScreen ? 'row' : 'column' }}>
        <Button
          id='LogoutNowButton'
          variant='secondary'
          onClick={handleLogout}
          style={{ width: isXSScreen ? '100%' : 'auto' }}
        >
          Log out now
        </Button>
        <Button
          id='ContinueSessionButton'
          onClick={handleCloseModal}
          style={{ width: isXSScreen ? '100%' : 'auto' }}
        >
          Continue for remaining time
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
